





import { ActionMethod, mapActions, mapState } from 'vuex'
import { Component, Vue } from "vue-property-decorator";
import { Action } from 'vuex-class'
import { IError } from '@/types/interfaces';


@Component({})
export default class ConnectPage extends Vue {
  @Action('connect', { namespace: 'shopify' }) connectShopify: ActionMethod;

  mounted() {
    this.connect()
  }

  connect() {
    this.connectShopify().then(result => {
      // this.$router.push({ name: 'shopify-login' })
      this.$router.push({ name: 'index' })
    }).catch((err: IError) => {
      console.log(err)
    })
  }
}
